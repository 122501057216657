<template>
  <a-card :bordered='false'>
      <a-row class="query-row" type='flex' :gutter="12">
         <a-col style="width:220px">
           <span>主订单号：</span>
           <a-input v-model="queryData.orderBn" allowClear placeholder='请输入'></a-input>
        </a-col>
        <a-col style="width:220px">
           <span>子订单号：</span>
           <a-input v-model="queryData.orderSn" allowClear placeholder='请输入'></a-input>
        </a-col>
        <a-col style="width:380px">
           <span>下单时间：</span>
           <a-range-picker 
            v-model="orderTime"
            :ranges="pickerRange" 
            :show-time="showTime"/>
        </a-col>
        <a-col style="width:200px">
           <span>分享人：</span>
           <a-input v-model="queryData.shareMobile" allowClear placeholder='请输入'></a-input>
        </a-col>
        <a-col style="width:200px">
           <span>下单人：</span>
           <a-input v-model="queryData.buyerMobile" allowClear placeholder='请输入'></a-input>
        </a-col>
        
        <a-col style="width:200px">
           <span>下单时直属上级：</span>
           <a-input v-model="queryData.pusherMobile" allowClear placeholder='请输入'></a-input>
        </a-col>
        <a-col style="width:220px">
           <span>订单状态：</span>
           <a-select v-model="queryData.status" allowClear placeholder='请选择'>
               <a-select-option v-for="(val,key) in orderStatus" :key="key" :value="key">{{val}}</a-select-option>
            </a-select>
        </a-col>
        <a-col style="width:200px">
           <span>购买方式：</span>
           <a-select v-model="queryData.referrerId" allowClear placeholder='请选择'>
                <a-select-option v-for="(val,key) in referrerId" :key="key" :value="key">{{val}}</a-select-option>
            </a-select>
        </a-col>
        <a-col>
           <a-button @click="() => {this.pageNum = 1;this.loadTableData()}" type='primary' style="margin-right:12px">查询</a-button>
           <a-button type='primary' @click="exportData()" v-auth='"orderListNew:orderListNewExp"'>导出</a-button>
        </a-col>
      </a-row>
      <a-table :columns="columns" :dataSource="tableData" :pagination="false" 
             :loading="loading"
             :scroll="{x: true}"
             size="default">
             <template slot="options" slot-scope="text, record">
               <a @click='showRewardDetail(record)' v-auth='"orderListNew:rewardDetail"'>查看奖励明细</a>
               <br>
               <a @click="getOrderTime(record)" v-auth='"orderListNew:orderTime"'>订单历史时间</a>
             </template>
      </a-table>
      <a-pagination
         style="display: flex; flex-direction: row; justify-content: flex-end; margin:10px 10px 5px 0"
         size="small"
         v-model="pageNum"
         :total="total"
         :showTotal="total => `共 ${total} 条`"
         showSizeChanger
         showLessItems
         showQuickJumper
         :pageSize.sync="pageSize"
         @change="loadTableData"
         @showSizeChange="() => {this.pageNum = 1; this.loadTableData()}"
      />

      <!--导出modal-->
      <ExportModal
         ref="exportModal"
         :exportUrl="exportUrl"
         :params="handleParams()"
         :total="total"
      >
      </ExportModal>

      <!-- 奖励明细弹窗 -->
      <a-modal v-drag-modal :destroyOnClose='true' width='70%' v-model="rewardModal" :footer='null' title='奖励明细' >
         <a-table :columns="rewardColumns" :dataSource="rewardTableData" :pagination="false"
             :loading="loading"
             :scroll="{x: true}"
             size="default">
         </a-table>
      </a-modal>

      <!-- 订单历史时间弹窗 -->
      <a-modal v-drag-modal :destroyOnClose='true' v-model="orderNodeModal" :footer='null' title='订单历史时间' >
         <div class="order-node-modal">
            <a-spin :spinning='orderTimeLoading'>
               <a-card hoverable v-for="item in orderHistoryTime" :key='item.key'>
                     <div :class="{'color-gray': item.colorGray}">
                        <span>{{item.title}}</span>
                        <span>{{moment(item.time * 1000).format('YYYY-MM-DD HH:mm:ss')}}</span>
                     </div>
               </a-card>
            </a-spin>
         </div>
      </a-modal>
  </a-card>
</template>

<script>
import moment from 'moment'
import api from '@/api'
import ExportModal from '@/components/ExportModal'
import { rewardStatus, buyMethodsNew} from '@/config'
export default {
   name:'OrderList',
   components: {
      ExportModal
   },
   data () {
      return {
         exportUrl: api.marketManage.exportOrderListNew,
         rewardStatus:rewardStatus,
         showTime:{
            format: 'HH:mm:ss',
            defaultValue:[moment('00:00:00', 'HH:mm:ss'),moment('23:59:59', 'HH:mm:ss')]
         },
         pageNum:1,
         total:0,
         pageSize:10,
         loading:false,
         columns:[
            {
               title: '序号',
               align: 'center',
               ellipsis:true,
               customRender: (text, record, index) => {
                  return index + 1
               }
            },
            {
               title: '主订单号',
               dataIndex: 'orderBn',
               align: 'center',
               ellipsis:true
            },
            {
               title: '子订单号',
               dataIndex: 'orderSn',
               align: 'center',
               ellipsis:true
            },
            {
               title: '订单价格',
               dataIndex: 'price',
               align: 'center',
               ellipsis:true
            },
            {
               title: '订单毛利',
               dataIndex: 'rewardPoint',
               align: 'center',
               ellipsis:true
            },
            {
               title: '下单人',
               dataIndex: 'buyerMobile',
               align: 'center',
               ellipsis:true
            },
            {
               title: '下单时间',
               dataIndex: 'payTime',
               align: 'center',
               ellipsis:true,
               customRender: text => {
                  return text ? moment(text * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
               }
            },
            {
               title: '分享人',
               dataIndex: 'shareMobile',
               align: 'center',
               ellipsis:true,
            },
            {
               title: '下单时直属上级',
               dataIndex: 'pusherUserStr',
               align: 'center',
               ellipsis:true
            },
            {
               title: '下单时直属上级身份',
               dataIndex: 'referrerIdSjLevelStr',
               align: 'center',
               ellipsis:true
            },
            {
               title: '订单状态',
               dataIndex: 'statusStr',
               align: 'center',
               ellipsis:true,
               customRender: (text, record) => {
                  if (record.refundStatus == '1') return '退款中'
                  // return this.orderStatus[text]
                  return text
               }
            },
            {
               title: '购买方式',
               dataIndex: 'referrerIdStr',
               align: 'center',
               ellipsis:true,
               // customRender: text => {
               //    return this.referrerId[text]
               // }
            },
            {
               title: '商品活动标签',
               dataIndex: 'promotionName',
               align: 'center',
               ellipsis:true
            },
            {
               title: '是否过售后',
               dataIndex: 'isAftersale',
               align: 'center',
               ellipsis:true,
               customRender: (text,record) => {
                  return ['否','是'][text] || ''
               }
            },
            {
               title: '操作',
               align: 'center',
               ellipsis: true,
               scopedSlots: { customRender: 'options' }
               // customRender: (text, record) => {
               //    return <a onClick={() => this.showRewardDetail(record)}>查看奖励明细</a>
               // }
            }

         ],
         tableData:[],
         queryData: {
            orderBn: undefined,
            orderSn: undefined,
            shareMobile: undefined,
            buyerMobile: undefined,
            pusherMobile: undefined,
            status: undefined,
            referrerId: undefined,
         },
         orderTime:[],
         rewardModal: false,
         orderNodeModal: false,
         rewardColumns:[
            {
               title: '主订单号',
               dataIndex: 'orderBn',
               align: 'center',
               ellipsis: true,
            },
            {
               title: '子订单号',
               dataIndex: 'orderSn',
               align: 'center',
               ellipsis: true,
            },
            {
               title: '受益人',
               dataIndex: 'mobile',
               align: 'center',
               ellipsis:true
            },
            {
               title: '分享人',
               dataIndex: 'shareMobile',
               align: 'center',
               ellipsis: true
            },
            
            {
               title: '奖励类型',
               dataIndex: 'rewardTypeStr',
               align: 'center',
               ellipsis:true,
            },
            {
               title: '积分数值',
               dataIndex: 'rewardAmount',
               align: 'center',
               ellipsis:true
            },
            {
               title: '奖励发放状态',
               dataIndex: 'statusStr',
               align: 'center',
               ellipsis:true,
               // customRender: text => {
               //    // 已取消显示红色 20为已取消
               //    // todo
               //    return (
               //       <span style={{color:text === 20 ? '#ff4d4f' : ''}}>{this.rewardStatus[text]}</span>
               //    )
               // }
            },
            {
               title: '发放时间',
               dataIndex: 'sentTimeStr',
               align: 'center',
               ellipsis:true,
            },
            {
               title: '退单时间',
               dataIndex: 'updateTimeStr',
               align: 'center',
               ellipsis:true,
            },
            
         ],
         rewardTableData: [],
         currentRow: {},
         //订单状态
         orderStatus: {
            '-1': '退款中',
            '1': '已支付',
            '2': '已收货',
            '-2': '退款成功'
         },
         //购买方式
         referrerId: buyMethodsNew,
         orderHistoryTime: [],
         orderTimeLoading: true,
         pickerRange:{}
      }
   },
   mounted () {
      this.initSearchParams()
      this.loadTableData()
   },
   methods:{
      moment,
      exportData () {
         this.$refs.exportModal.show()
      },
      handleParams () {
         let payBeginTime = this.orderTime[0] ? this.orderTime[0].valueOf() / 1000 : undefined
         let payEndTime = this.orderTime[1] ? this.orderTime[1].valueOf() / 1000 : undefined
         let p = {
            ...this.queryData,
            payBeginTime,
            payEndTime,
            pageNum:this.pageNum,
            pageSize:this.pageSize
         }
         for (const key in p) {
            if (p[key] == '' || p[key] == undefined) {
               delete p[key]
            }
         }
         return p
      },
      // 初始化参数，第一次进入页面只查当天时间的
      initSearchParams(){
         const day_start = moment().startOf('day')
         const day_end = moment().endOf('day')
         this.pickerRange = { 
            '今日': [moment().startOf('day'), moment().endOf('day')], 
            '本周': [moment().startOf('week'), moment().endOf('week')], 
            '本月': [moment().startOf('month'), moment().endOf('month')]
         }
         // this.queryData.orderTime = [day_start,day_end]
      },
      loadTableData () {
         this.loading = true
         console.log(this.handleParams())
         api.marketManage.orderListNew(this.handleParams()).then(res => {
            if (res.code === '00000') {
               this.tableData = res.data.dataList
               this.total = res.data.total
            } else {
               this.$message.error(res.msg)
            }
         }).finally(() => this.loading = false)
      },
      showRewardDetail (record) {
         this.currentRow = record
         this.loading = true
         this.rewardModal = true
         let params = {orderSn: record.orderSn}
         api.marketManage.getAllRewardList(params).then(res => {
            if (res.code === '00000') {
               this.rewardTableData = res.data.dataList
            } else {
               this.$message.error(res.msg)
            }
         }).finally(() => {this.loading = false})
      },
      getOrderTime ({orderSn}) {
         this.orderNodeModal = true
         this.orderTimeLoading = true
         api.marketManage.orderTime({orderSn}).then(res => {
            if (res.code !== '00000') return this.$message.error(res.msg)
            let timeArr = [
               {
                  title: '下单时间',
                  key: 'payTime'
               },
               {
                  title: '收货时间',
                  key: 'endTime'
               },
               {
                  title: '申请退款',
                  key: 'refundApplyTime'
               },
               {
                  title: '取消退款',
                  key: 'refundCancelTime'
               },
               {
                  title: '退款成功',
                  key: 'refundSuccessTime'
               },
               {
                  title: '售后时间',
                  key: 'afterSalesTime'
               },
            ]
            this.orderHistoryTime = timeArr.map(i => {
               return {
                  ...i,
                  time: res.data[i.key],
                  // 若订单在收货后发起申请退款( refundStatus=1)，售后时间置灰；
                  colorGray: i.key === 'afterSalesTime' && res.data.refundStatus === 1,
                  title: i.key === 'endTime' ? res.data.isUserConfirm === 0 ? `${i.title}（系统自动确认）` : `${i.title}（手动确认）` : i.title
               }
            }).sort((a, b) => a.time - b.time).filter(c => c.time)
            this.orderTimeLoading = false
         })
      },
   }
}
</script>

<style scoped lang='less'>
   .order-node-modal /deep/ .ant-card {
      margin-bottom: 12px;
      cursor: auto;
      .ant-card-body {
         & > div {
            display: flex;
            justify-content: space-between;
         }
         & > .color-gray {
            color: #d9d9d9;
         }
      }
   } 
</style>